<!--
 * @Author: your name
 * @Date: 2021-04-21 14:30:59
 * @LastEditTime: 2021-05-08 10:25:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \test\src\components\res\Jpjk\JpjkIndex.vue
-->
<template>
  <div class='JpjkIndex'>
    <iframe :src="iframeSrc" frameborder="0"></iframe>
  </div>
</template>
<script>

export default {
  name: 'JpjkIndex',
  props:[],
  components: {},
  data () {
    return {
      iframeSrc: null
    }
  },
  computed: {},
  watch: {},
  created () {
    const domains = location.hostname.split('.');
    const secondDomain = domains.slice(-2).join('.');
    document.domain = secondDomain;

    let param = {
      "module": "qualitypaper",
      "hidenav": "true"
    }    
    let url = this.getZznScJumpUrl(param)
    this.iframeSrc = url
  }, 
  mounted () {
  },
  methods: {

  }
}
</script>

<style lang='less' scoped>
.JpjkIndex{
    padding-left: 85px;
    padding-right: 0;
    overflow: hidden;
    iframe{
        width: 100%;
        height: 100%;
    }
}
</style>
